import './App.css';
import Header from './views/Header';
import { AllRoutes } from './views/Routes';
import { ToastContainer } from 'react-toastify';
import { generateToken, messaging } from './firebase';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { onMessage } from 'firebase/messaging';
import Login from './views/Login';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    generateToken();
    onMessage(messaging, (payload) => {
      // Handle incoming messages here
    });
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <div className="App">
      {isAuthenticated ? (
        <>
          <Header />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          />
          <ToastContainer />
          <AllRoutes />
        </>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;
